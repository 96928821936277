import {
  OPEN_LOGIN_MODAL,
  OPEN_LOGOUT_MODAL,
  OPEN_REG_MODAL,
  OPEN_VIDEO_MODAL,
  CLOSE_LOGIN_MODAL,
  CLOSE_LOGOUT_MODAL,
  CLOSE_REG_MODAL,
  CLOSE_VIDEO_MODAL,
} from '../types'

const initialState = {
  isLoginModalOpen: false,
  isLogoutModalOpen: false,
  isRegModalOpen: false,
  isVideoModalOpen: false,
}

export const modals = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_LOGIN_MODAL:
      return {
        ...initialState,
        isLoginModalOpen: true,
      }
    case OPEN_LOGOUT_MODAL:
      return {
        ...initialState,
        isLogoutModalOpen: true,
      }
    case OPEN_REG_MODAL:
      return {
        ...initialState,
        isRegModalOpen: true,
      }
    case OPEN_VIDEO_MODAL:
      return {
        ...initialState,
        isVideoModalOpen: true,
      }
    case CLOSE_LOGIN_MODAL:
    case CLOSE_LOGOUT_MODAL:
    case CLOSE_REG_MODAL:
    case CLOSE_VIDEO_MODAL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
