import React from 'react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import createStore from './src/store'

export default ({ element }) => {
  const store = createStore()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>{element}</Provider>
    </QueryClientProvider>
  )
}
