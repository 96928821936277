export const LOGIN = 'APP/LOGIN'
export const LOGOUT = 'APP/LOGOUT'
export const SET_AUTH_ERROR = 'APP/SET_AUTH_ERROR'
export const RESET_AUTH_ERROR = 'APP/RESET_AUTH_ERROR'
export const SET_CATEGORY = 'APP/SET_CATEGORY'

export const OPEN_LOGIN_MODAL = 'MODALS/OPEN_LOGIN_MODAL'
export const OPEN_LOGOUT_MODAL = 'MODALS/OPEN_LOGOUT_MODAL'
export const OPEN_REG_MODAL = 'MODALS/OPEN_REG_MODAL'
export const OPEN_VIDEO_MODAL = 'MODALS/OPEN_VIDEO_MODAL'
export const CLOSE_LOGIN_MODAL = 'MODALS/CLOSE_LOGIN_MODAL'
export const CLOSE_LOGOUT_MODAL = 'MODALS/CLOSE_LOGOUT_MODAL'
export const CLOSE_REG_MODAL = 'MODALS/CLOSE_REG_MODAL'
export const CLOSE_VIDEO_MODAL = 'MODALS/CLOSE_VIDEO_MODAL'

export const OPEN_VICTORINA = 'APP/OPEN_VICTORINA'
export const CLOSE_VICTORINA = 'APP/CLOSE_VICTORINA'
