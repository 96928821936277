import {
  LOGIN,
  LOGOUT,
  SET_AUTH_ERROR,
  RESET_AUTH_ERROR,
  OPEN_VICTORINA,
  CLOSE_VICTORINA,
  SET_CATEGORY,
} from '../types'

let isAuth
if (typeof window === 'undefined') {
  isAuth = false
} else {
  isAuth = Boolean(
    window.localStorage.getItem('access_token') &&
      window.localStorage.getItem('refresh_token')
  )
}

const initialState = {
  isAuth,
  isAnswer: false,
  category: null,
}

export const app = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuth: true,
      }
    case LOGOUT:
      return {
        ...initialState,
        isAuth: false,
      }
    case SET_AUTH_ERROR:
      return {
        ...state,
        hasAuthError: true,
      }
    case RESET_AUTH_ERROR:
      return {
        ...state,
        hasAuthError: false,
      }
    case OPEN_VICTORINA:
      return {
        ...state,
        isAnswer: true,
        infoVictorina: action.payload,
      }
    case CLOSE_VICTORINA:
      return {
        ...state,
        isAnswer: false,
        infoVictorina: '',
      }
    case SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      }
    default:
      return state
  }
}
