// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-heading-js": () => import("./../../../src/pages/heading.js" /* webpackChunkName: "component---src-pages-heading-js" */),
  "component---src-pages-headings-js": () => import("./../../../src/pages/headings.js" /* webpackChunkName: "component---src-pages-headings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lecture-js": () => import("./../../../src/pages/lecture.js" /* webpackChunkName: "component---src-pages-lecture-js" */),
  "component---src-pages-lectures-js": () => import("./../../../src/pages/lectures.js" /* webpackChunkName: "component---src-pages-lectures-js" */),
  "component---src-pages-mapimg-js": () => import("./../../../src/pages/mapimg.js" /* webpackChunkName: "component---src-pages-mapimg-js" */),
  "component---src-pages-medialibrary-js": () => import("./../../../src/pages/medialibrary.js" /* webpackChunkName: "component---src-pages-medialibrary-js" */),
  "component---src-pages-medialibraryphoto-js": () => import("./../../../src/pages/medialibraryphoto.js" /* webpackChunkName: "component---src-pages-medialibraryphoto-js" */),
  "component---src-pages-medialibraryvideo-js": () => import("./../../../src/pages/medialibraryvideo.js" /* webpackChunkName: "component---src-pages-medialibraryvideo-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-techwork-js": () => import("./../../../src/pages/techwork.js" /* webpackChunkName: "component---src-pages-techwork-js" */)
}

